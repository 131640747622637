// BrowseTab.jsx
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Tabs from '../component/Tabs.jsx';
import Tab from '../component/Tab.jsx';

import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import { color, headerHeight } from '../style/variables.js';
import media from '../style/media.js';

const tabObject = {
  explore: {
    paths: ['/'],
    titleI18nKey: 'explore_title',
    mixpanelButtonId: ButtonId.Tab.TabExplore,
  },
  following: {
    paths: ['/following'],
    titleI18nKey: 'title_following_list',
    mixpanelButtonId: ButtonId.Tab.TabFollowing,
  },
  ['category-overview']: {
    paths: ['/category-overview'],
    titleI18nKey: 'general_categories',
    mixpanelButtonId: ButtonId.Tab.TabCategory,
  },
};
const tabStyle = {
  opacity: 1,
};

const defaultArray = [];
const defaultFunction = () => null;

export const BrowseTab = ({
  meId = '',
  tabs = defaultArray,
  replace = defaultFunction,
  push = defaultFunction,
}) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const activeTab = Object.keys(tabObject).find(key =>
    tabObject[key].paths.includes(pathname)
  );
  const handleTabChange = useCallback(
    (event, value) => {
      event?.preventDefault();
      if ('category-overview' === value) {
        return push({
          pathname: `/${value}`,
          search: location.search,
        });
      }
      return replace({
        pathname: `/${'explore' === value ? '' : value}`,
        search: location.search,
      });
    },
    [push, replace]
  );

  const getTabHref = useCallback(
    tabKey => `/${'explore' === tabKey ? '' : tabKey}${search}`,
    [search]
  );

  if (!tabs.length || !meId) {
    return null;
  }
  return (
    <StyledBrowseTab>
      <Tabs value={activeTab} onChange={handleTabChange}>
        {tabs.map(tabId => {
          const isActive = tabId === activeTab;
          const i18nKey = tabObject[tabId]?.titleI18nKey;
          const title =
            t(i18nKey, {
              ns: TranslationNamespace.GENERAL,
            }) || i18nKey;
          return (
            <Tab
              data-element_id={tabObject[tabId]?.mixpanelButtonId}
              key={tabId}
              value={tabId}
              labelStyle={tabStyle}
              label={<TabText isActive={isActive}>{title}</TabText>}
              href={getTabHref(tabId)}
            />
          );
        })}
      </Tabs>
    </StyledBrowseTab>
  );
};

BrowseTab.propTypes = {
  meId: PropTypes.string,
  tabs: PropTypes.array,
  replace: PropTypes.func,
  push: PropTypes.func,
};

const StyledBrowseTab = styled.div`
  position: sticky;
  top: calc(var(--space-to-pinned-notification) + ${headerHeight}px);
  left: 0px;
  z-index: 7; // Should higher than HomeSearchBar
  width: 100%;
  display: none;
  border-bottom: 1px solid rgba(100, 100, 100, 0.15);
  background-color: ${color.black};
  ${media.mobile`
    display: block;
  `};
`;

const TabText = styled.h2`
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  ${media.mobile`
    font-size: 14px;
  `};
`;

export default BrowseTab;
