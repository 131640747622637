// Tab.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { textColor } from '../style/variables.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import media from '../style/media.js';

class Tab extends React.PureComponent {
  handleClick = event => {
    const { value, onChange } = this.props;
    onChange(event, value);
  };

  render() {
    const { label, value, onChange, labelStyle, ...otherProps } = this.props;
    return (
      <StyledTab onClick={this.handleClick} {...otherProps} data-key={value}>
        <Label style={labelStyle}>{label}</Label>
      </StyledTab>
    );
  }
}

Tab.propTypes = {
  label: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.any,
  labelStyle: PropTypes.object,
};

Tab.defaultProps = {
  onChange: () => null,
  label: '',
};

const StyledTab = styled.a`
  ${({ as }) => {
    if (as === 'button') {
      return css`
        ${ResetButtonStyle};
      `;
    }
  }}
  display: block;
  transition: 0.2s;
  color: ${textColor.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  cursor: pointer;
`;

const Label = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  padding: 16px 2px;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  ${media.mobile`
    padding: 12px 2px;
    font-size: 14px;
  `};
`;

export default Tab;
